import "./App.css";
import background from "./assets/bg_spapes.jpg";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { Flex } from "@chakra-ui/react";
import { useImage } from "react-image";

import styled, { css } from "styled-components";
import { createStandaloneToast } from "@chakra-ui/react";

import * as React from "react";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  WagmiConfig,
  createClient,
  mainnet,
  configureChains,
  usePrepareContractWrite,
  useContractWrite,
  useContractRead,
  useWaitForTransaction,
  useAccount,
} from "wagmi";
import { ConnectKitProvider, ConnectKitButton } from "connectkit";

import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { InjectedConnector } from "wagmi/connectors/injected";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";

const
  NAME = "APEx",
  MOBILE_QUERY = "(max-width: 700px)",
  MID_QUERY = "(max-width: 900px)",
  TABLET_QUERY = "(max-width: 1200px)",
  HI_RES_QUERY = "(min-width: 2048px)",
  SUPPORTED_CHAINS = [mainnet],
  CONTRACT_ADDRESS = "0x20f5a633355b3F8046C1305137F22CBe14558dc1";

const { chains, provider, webSocketProvider } = configureChains(SUPPORTED_CHAINS, [
  alchemyProvider({ apiKey: "DrRvg0YB3R7HAIURHCqGB2QaIaTOM8TN" }),
  publicProvider(),
]);

const { ToastContainer, toast } = createStandaloneToast();

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

const client = createClient({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: NAME,
      },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        qrcode: true,
      },
    }),
    new InjectedConnector({
      chains,
      options: {
        name: "Injected",
        shimDisconnect: true,
      },
    }),
  ],
  provider,
  webSocketProvider,
});

const StyledButton = styled.button`
padding: 0px 12px;
color: white;
cursor: pointer;
border-radius: 12px;
font-family: InputMonoMedium, monospace;
box-sizing: border-box;
outline: none;
border: none;
transition: 200ms ease;
filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.5));
background: #2759FF;
&:hover {
  background: #3C69FF;
}
  ${(props) =>
    props.disabled &&
    css`
    pointer-events: none;
    opacity: 0.65;
  `}
  ${(props) =>
    props.marginTop &&
    css`
    margin-top: ${props.marginTop};
  `}
  ${(props) =>
    props.marginLeft &&
    css`
    margin-left: ${props.marginLeft};
  `}
  ${(props) =>
    props.width &&
    css`
    font-size: ${props.width};
  `}
  ${(props) =>
    props.fontSize &&
    css`
    font-size: ${props.fontSize};
  `}
  ${(props) =>
    props.height &&
    css`
    height: ${props.height};
  `}`;

function Mint() {
  const isMobile = useMediaQuery({ query: MOBILE_QUERY });
  const width = useWindowSize().width ?? window.innerWidth;
  const buttonHeight = `${Math.min(Math.floor(width / 9), 40)}px`;
  const fontSize = `${Math.min(Math.floor(width / 30), 16)}px`;
  const { isConnected, address } = useAccount();

  const [merkleProof, setMerkleProof] = useState(["0x0000000000000000000000000000000000000000000000000000000000000000"]);

  const {
    config,
    error: prepareError,
    isError: isPrepareError,
  } = usePrepareContractWrite({
    address: CONTRACT_ADDRESS,
    abi: [
      {
        name: "mint",
        type: "function",
        stateMutability: "nonpayable",
        inputs: [
          {
            internalType: "bytes32[]",
            name: "_merkleProof",
            type: "bytes32[]"
          }
        ],
        outputs: [],
      },
    ],
    functionName: "mint",
    args: [merkleProof],
  });

  const { data, error, isError, write } = useContractWrite(config);

  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  });

  const [activated, setActivated] = useState(false);

  useEffect(() => {
    if (activated && isSuccess) {
      toast({
        title: "Success",
        description: `Transaction confirmed!`,
        status: "success",
        duration: 3000,
        position: "bottom-right",
        isClosable: true
      });
    } else if (!isLoading && activated) {
      const err = error?.message;
      if (err)
        toast({
          title: "Error",
          description: `${err.substring(0, 50)}...`,
          status: "error",
          duration: 3000,
          position: "bottom-right",
          isClosable: true
        });
      else
        toast({
          title: "Error",
          description: `Transaction failed.`,
          status: "error",
          duration: 3000,
          position: "bottom-right",
          isClosable: true
        });
    }
  }, [isLoading]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(`https://us-central1-apex-nft.cloudfunctions.net/whitelist/${address}`, {
        method: "GET"
      });
      const data = await res.json();
      const proof = data.proof &&
        data.proof.constructor === Array &&
        data.proof.length !== 0
        ? data.proof : ["0x0000000000000000000000000000000000000000000000000000000000000000"];
      setMerkleProof(proof);
    }

    fetchData().catch(console.error);
  }, [address]);

  /*useEffect(() => {
    console.log("Obtained whitelist merkle proof", merkleProof);
  }, [merkleProof]);*/

  useContractRead({
    address: CONTRACT_ADDRESS,
    watch: isConnected,
    abi: [
      {
        name: "privateMint",
        type: "function",
        stateMutability: "view",
        inputs: [],
        outputs: [{
          internalType: "bool",
          name: "",
          type: "bool",
        }],
      },
    ],
    functionName: "privateMint",
    onSuccess(data) {
      setActivated(data);
    },
  });

  return (
    <ConnectKitButton.Custom>
      {({ isConnected }) => {
        return (
          isConnected ? <>
            <StyledButton
              marginLeft={isMobile ? "10px" : undefined}
              marginTop={isMobile ? undefined : "10px"}
              fontSize={isMobile ? fontSize : "16px"}
              height={isMobile ? buttonHeight : "40px"}
              width={isMobile ? "100%" : undefined}
              disabled={activated && write ? isLoading : true}
              onClick={() => write()}>
              {isLoading ? "Minting..." : (isMobile ? "Mint" : "Mint your APEx")}
            </StyledButton>
          </> : <></>
        );
      }}
    </ConnectKitButton.Custom>
  );
}

const ConnectButton = () => {
  const isMobile = useMediaQuery({ query: MOBILE_QUERY });
  const width = useWindowSize().width ?? window.innerWidth;
  const buttonHeight = `${Math.min(Math.floor(width / 9), 40)}px`;
  const fontSize = `${Math.min(Math.floor(width / 30), 16)}px`;

  return (
    <ConnectKitButton.Custom>
      {({ isConnected, show, truncatedAddress, ensName }) => {
        return (
          <StyledButton fontSize={isMobile ? fontSize : "16px"} height={isMobile ? buttonHeight : "40px"} width={isMobile ? "100%" : undefined} onClick={show}>
            {isConnected ? ensName ?? (isMobile ? `0x..${truncatedAddress.substring(truncatedAddress.length - 4, truncatedAddress.length)}` : truncatedAddress) : "Connect"}
          </StyledButton>
        );
      }}
    </ConnectKitButton.Custom>
  );
};

const ApexImg = styled.img`
border-radius: 6px;
`;

function Copyright() {
  const isMobile = useMediaQuery({ query: MOBILE_QUERY });

  const CopyrightNotice = styled.div`
  position: relative;
  bottom: 0;
  right: 0;
  font-size: ${isMobile ? "8px" : "12px"};
  font-family: InputMonoRegular, monospace;
  width: 100%;
  color: rgba(255, 255, 255, 0.4);
  text-align: right;
  padding: 0 25px 10px 10px;
  box-sizing: border-box;
  `;

  const LicenseLink = styled.a`
  &:link, &:visited, &:hover, &:active {
    color: rgba(255, 255, 255, 0.4);
    text-decoration: underline;
  }`;

  return (
    <CopyrightNotice>
      &copy;{new Date().getFullYear()} Capt. Krill &nbsp; <LicenseLink href="https://arweave.net/zmc1WTspIhFyVY82bwfAIcIExLFH5lUcHHUN0wXg4W8/3">License</LicenseLink>
    </CopyrightNotice>
  );
}

function Carousel() {
  const isMobile = useMediaQuery({ query: MOBILE_QUERY });
  const isHiRes = useMediaQuery({ query: HI_RES_QUERY });
  const width = useWindowSize().width ?? window.innerWidth;
  const perPage = width < 800 ? 3 : width < 1200 ? 4 : 5;
  const margin = Math.floor(width * 0.1);
  const imgWidth = isMobile ? Math.floor(width / (perPage * 0.66667)) - 5 : Math.floor(width / perPage) - 5;
  const hiResWidth = isHiRes ? 300 : 200;

  return (
    <Flex direction="column" justifyContent="flex-start" marginRight={perPage >= 5 ? "8px" : isMobile ? imgWidth * -0.5 : {}} marginLeft={perPage >= 5 ? {} : isMobile ? imgWidth * -0.5 : {}} marginTop={isMobile ? margin : isHiRes ? "215px" : "205px"} marginBottom={isMobile ? "8px" : {}} style={{
      filter: "drop-shadow(0 0 5px rgba(0, 0, 0, 0.9))"
    }}>
      <Splide
        hasTrack={false}
        options={{
          type: "loop",
          perPage: perPage,
          perMove: 1,
          speed: 300,
          pagination: false,
          wheel: true,
          drag: true,
          snap: true,
          wheelMinThreshold: 30,
          releaseWheel: true,
          waitForTransition: true,
          direction: "rtl",
          width: isHiRes ? 308 * perPage : isMobile ? (imgWidth + 8) * perPage : 208 * perPage,
        }}
      >
        <SplideTrack
          className="apexCarousel">
          <SplideSlide>
            <ApexImg src="/img/apex1.jpg" width={isMobile ? imgWidth - 2 : hiResWidth} />
          </SplideSlide>
          <SplideSlide>
            <ApexImg src="/img/apex2.jpg" width={isMobile ? imgWidth - 2 : hiResWidth} />
          </SplideSlide>
          <SplideSlide>
            <ApexImg src="/img/apex3.jpg" width={isMobile ? imgWidth - 2 : hiResWidth} />
          </SplideSlide>
          <SplideSlide>
            <ApexImg src="/img/apex4.jpg" width={isMobile ? imgWidth - 2 : hiResWidth} />
          </SplideSlide>
          <SplideSlide>
            <ApexImg src="/img/apex5.jpg" width={isMobile ? imgWidth - 2 : hiResWidth} />
          </SplideSlide>
          <SplideSlide>
            <ApexImg src="/img/apex6.jpg" width={isMobile ? imgWidth - 2 : hiResWidth} />
          </SplideSlide>
          <SplideSlide>
            <ApexImg src="/img/apex7.jpg" width={isMobile ? imgWidth - 2 : hiResWidth} />
          </SplideSlide>
          <SplideSlide>
            <ApexImg src="/img/apex8.jpg" width={isMobile ? imgWidth - 2 : hiResWidth} />
          </SplideSlide>
          <SplideSlide>
            <ApexImg src="/img/apex9.jpg" width={isMobile ? imgWidth - 2 : hiResWidth} />
          </SplideSlide>
          <SplideSlide>
            <ApexImg src="/img/apex10.jpg" width={isMobile ? imgWidth - 2 : hiResWidth} />
          </SplideSlide>
          <SplideSlide>
            <ApexImg src="/img/apex11.jpg" width={isMobile ? imgWidth - 2 : hiResWidth} />
          </SplideSlide>
          <SplideSlide>
            <ApexImg src="/img/apex12.jpg" width={isMobile ? imgWidth - 2 : hiResWidth} />
          </SplideSlide>
          <SplideSlide>
            <ApexImg src="/img/apex13.jpg" width={isMobile ? imgWidth - 2 : hiResWidth} />
          </SplideSlide>
          <SplideSlide>
            <ApexImg src="/img/apex14.jpg" width={isMobile ? imgWidth - 2 : hiResWidth} />
          </SplideSlide>
          <SplideSlide>
            <ApexImg src="/img/apex15.jpg" width={isMobile ? imgWidth - 2 : hiResWidth} />
          </SplideSlide>
          <SplideSlide>
            <ApexImg src="/img/apex16.jpg" width={isMobile ? imgWidth - 2 : hiResWidth} />
          </SplideSlide>
          <SplideSlide>
            <ApexImg src="/img/apex17.jpg" width={isMobile ? imgWidth - 2 : hiResWidth} />
          </SplideSlide>
          <SplideSlide>
            <ApexImg src="/img/apex18.jpg" width={isMobile ? imgWidth - 2 : hiResWidth} />
          </SplideSlide>
          <SplideSlide>
            <ApexImg src="/img/apex19.jpg" width={isMobile ? imgWidth - 2 : hiResWidth} />
          </SplideSlide>
          <SplideSlide>
            <ApexImg src="/img/apex20.jpg" width={isMobile ? imgWidth - 2 : hiResWidth} />
          </SplideSlide>
          <SplideSlide>
            <ApexImg src="/img/apex21.jpg" width={isMobile ? imgWidth - 2 : hiResWidth} />
          </SplideSlide>
          <SplideSlide>
            <ApexImg src="/img/apex22.jpg" width={isMobile ? imgWidth - 2 : hiResWidth} />
          </SplideSlide>
          <SplideSlide>
            <ApexImg src="/img/apex23.jpg" width={isMobile ? imgWidth - 2 : hiResWidth} />
          </SplideSlide>
          <SplideSlide>
            <ApexImg src="/img/apex24.jpg" width={isMobile ? imgWidth - 2 : hiResWidth} />
          </SplideSlide>
          <SplideSlide>
            <ApexImg src="/img/apex25.jpg" width={isMobile ? imgWidth - 2 : hiResWidth} />
          </SplideSlide>
          <SplideSlide>
            <ApexImg src="/img/apex26.jpg" width={isMobile ? imgWidth - 2 : hiResWidth} />
          </SplideSlide>
        </SplideTrack>
        <div className="splide__arrows">
          {isMobile ? <><button style={{ marginRight: (imgWidth * 0.5) + 5 }} className="splide__arrow splide__arrow--prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" width="40" height="40" focusable="false"><path d="m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z"></path></svg></button>
            <button style={{ marginLeft: (imgWidth * 0.5) + 5 }} className="splide__arrow splide__arrow--next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" width="40" height="40" focusable="false"><path d="m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z"></path></svg></button></> : <></>}
        </div>
      </Splide>
    </Flex>
  );
}

function MintStats() {
  const isMobile = useMediaQuery({ query: MOBILE_QUERY });
  const [currentSupply, setCurrentSupply] = useState(0);
  const [maxSupply, setMaxSupply] = useState(0);
  const [activated, setActivated] = useState(false);
  const { isConnected } = useAccount();

  useContractRead({
    address: CONTRACT_ADDRESS,
    watch: isConnected,
    abi: [
      {
        name: "supply",
        type: "function",
        stateMutability: "view",
        inputs: [],
        outputs: [{
          internalType: "uint256",
          name: "",
          type: "uint256",
        }],
      },
    ],
    functionName: "supply",
    onSuccess(data) {
      setMaxSupply(data.toNumber());
    },
  });

  useContractRead({
    address: CONTRACT_ADDRESS,
    watch: isConnected,
    abi: [
      {
        name: "privateMint",
        type: "function",
        stateMutability: "view",
        inputs: [],
        outputs: [{
          internalType: "bool",
          name: "",
          type: "bool",
        }],
      },
    ],
    functionName: "privateMint",
    onSuccess(data) {
      setActivated(data);
    },
  });

  useContractRead({
    address: CONTRACT_ADDRESS,
    watch: isConnected,
    abi: [
      {
        name: "totalSupply",
        type: "function",
        stateMutability: "view",
        inputs: [],
        outputs: [{
          internalType: "uint256",
          name: "",
          type: "uint256",
        }],
      },
    ],
    functionName: "totalSupply",
    onSuccess(data) {
      setCurrentSupply(data.toNumber());
    },
  });

  return (
    maxSupply ?
      <Flex
        marginTop={isMobile ? {} : "10px"}
        marginLeft={isMobile ? "10px" : {}}
        width="100%"
        style={{
          fontFamily: "InputMonoRegular, monospace",
          fontSize: "12px",
          justifyContent: "center",
        }}
      >
        <Flex
          alignItems="center"
          style={{
            padding: "10px",
            borderRadius: "12px",
            background: "rgba(8, 5, 23, 0.5)",
          }}>
          {`${activated ? currentSupply : 0}/${maxSupply} MINTED`}
        </Flex>
      </Flex> : <></>
  );
}

function App() {
  const { src } = useImage({
    srcList: background,
  });

  const AppStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  overflow-x: hidden;
  color: white;
  min-height: 100%;
  @media screen and (min-aspect-ratio: 1/1) {
    background: linear-gradient(to top, rgba(15, 196, 120, 0), rgba(15, 196, 120, 0.9)), url(${src}) no-repeat center center / cover;
  }
  
  @media screen and (max-aspect-ratio: 1/1) {
    background: linear-gradient(to top, rgba(15, 196, 120, 0), rgba(15, 196, 120, 0.9)), url(${src}) no-repeat center / cover;
  }
  `;

  const isMobile = useMediaQuery({ query: MOBILE_QUERY });
  const isTablet = useMediaQuery({ query: TABLET_QUERY });
  const isHiRes = useMediaQuery({ query: HI_RES_QUERY });
  const isMid = useMediaQuery({ query: MID_QUERY });
  const width = useWindowSize().width ?? window.innerWidth;
  const buttonGap = Math.floor(width * 0.07);
  const textPadding = Math.floor(width * 0.05);
  const contentWidth = isMobile ? width : isTablet ? Math.min(width - 100, 800) : isHiRes ? 1200 : 800;

  const perPage = width < 800 ? 3 : width < 1200 ? 4 : 5;
  const imgWidth = Math.floor(width / perPage) - 5;
  const hiResWidth = isHiRes ? 300 : 200;
  const imgHeight = Math.floor((isMobile ? imgWidth : hiResWidth) / 0.667) + 8;

  const TwitterLink = styled.a`
  &:link, &:visited, &:hover, &:active {
    color: rgba(255, 255, 255, 1);
    text-decoration: underline;
  }`;

  return (
    <AppStyled>
      <ToastContainer />
      <Flex direction="column" style={{
        position: "absolute",
        width: `${contentWidth}px`,
        background: "rgba(8, 5, 23, 0.65)",
        backdropFilter: "blur(10px)"
      }}>
        <Flex
          direction={isMobile ? "column" : "row"}
          gap={isMobile ? buttonGap : isTablet ? Math.min(buttonGap, 40) : {}}
          height={isMobile ? {} : "200px"}
          marginTop={isMobile ? 40 : {}}
          marginLeft={isMobile ? {} : 40}
          marginRight={isMobile ? {} : 40}
          alignItems="center"
          justifyContent="center">
          <Flex
            className="logo"
            width={isMobile ? "100%" : Math.floor(contentWidth / 3)}
            justifyContent={isMobile ? "center" : {}}>
            <img src="/img/APExLogo.svg" style={{
              maxWidth: isMobile ? width - 40 : isHiRes ? "340px" : "226px",
              maxHeight: isMobile ? (width - 40) * 0.618 : isHiRes ? "180px" : "120px",
            }}
              width={isMobile ? "100%" : isHiRes ? 340 : 226}
              height={isMobile ? "100%" : isHiRes ? 180 : 120} />
          </Flex>
          <Flex
            className="socials"
            width={isMobile ? "100%" : Math.floor(contentWidth / 3)}
            height={20}
            marginLeft={isHiRes ? "60px" : {}}
            gap={isMobile ? buttonGap : 20}
            justifyContent={isHiRes ? "flex-start" : "center"}
          >
            <a href="https://twitter.com/apexgenesisnft"><img src="/img/icontwitter.svg" height={isMobile ? 20 : 20} /></a>
            <a href="https://etherscan.io/address/0x20f5a633355b3f8046c1305137f22cbe14558dc1"><img src="/img/iconetherscan.svg" height={isMobile ? 20 : 20} /></a>
            <a href="https://opensea.io/collection/apexgen"><img src="/img/iconopensea.svg" height={isMobile ? 20 : 20} /></a>
          </Flex>
          <Flex direction="column" width={isMobile ? "100%" : Math.floor(contentWidth / 3)} alignItems={isMobile ? "center" : "flex-end"}>
            <WagmiConfig client={client}>
              <div
                width="100%"
              >
                <Flex
                  direction={isMobile ? "row" : "column"}
                  justifyContent={isMobile ? "center" : {}}
                >
                  <ConnectKitProvider theme="auto" mode="dark">
                    <MintStats />
                  </ConnectKitProvider>
                </Flex>
              </div>
            </WagmiConfig>
          </Flex>
        </Flex>
        {isMobile ? <Carousel /> : <></>}
        <Flex direction="column" marginTop={isMobile ? {} : `${imgHeight}px`}>
          <Flex
            flexDirection="column"
            style={{
              borderBottom: "1px solid rgba(255, 255, 255, 0.7)",
              fontFamily: "InputMonoRegular, monospace",
              fontSize: isMobile ? "4vw" : isHiRes ? 20 : 16,
              lineHeight: 1.5,
            }}>
            <Flex
              padding="40px"
            >
              The APEx Genesis collection features 1000 unique ape NFTs created by AI artist Captain Krill.
              They illuminate a future where technologically enhanced apes navigate society, their identity, and their relationship with humans.
            </Flex>
            <Flex
              padding="0 40px 20px 40px"
            >
              Each token serves as a Flight Pass for upcoming adventures within the APEx Universe.
              Holders will be whitelisted for future APEx projects, awarded exclusive airdrops, and become founding members of the APEx Community.
            </Flex>
            <Flex
              padding="20px 40px 40px 40px"
            >
              An APEx Genesis token is just the beginning. A deeper journey awaits; one that will transform those who take the first step.
              Take the banana.
            </Flex>
          </Flex>
          <Flex
            direction="column"
            alignItems="flex-start"
            style={{
              height: Math.floor(contentWidth * 0.567),
              padding: isMobile ? `${textPadding}px` : "40px 40px 0 40px",
              gap: isMobile ? textPadding * 2 : 40,
              background: `url("img/BG_1.jpg") no-repeat center center / cover`,
            }}>
            <div
              style={{
                backgroundColor: "rgba(8, 5, 23, 0.8)",
                backdropFilter: "blur(10px)",
                borderTop: "1px solid rgba(255, 255, 255, 0.7)",
                borderBottom: "1px solid rgba(255, 255, 255, 0.7)",
                marginTop: Math.floor(contentWidth * 0.567 * 0.1),
                padding: isMobile ? `${textPadding}px` : "40px",
                width: "50%",
                fontFamily: "InputMonoRegular, monospace",
                fontSize: isMobile ? "2.5vw" : isMid ? 10 : 14,
                lineHeight: 1.5,
              }}>
              Hmm. Another human by the looks of it. You don’t smell any different from the others.
              Our species have a lot in common now, but us apes have always been the different ones.
              The others. Outcasts. From the shadows of our forests, we watched you humans leave us behind.
            </div>
          </Flex>
          <Flex
            direction="column"
            alignItems="flex-end"
            style={{
              height: Math.floor(contentWidth * 0.567),
              padding: isMobile ? `${textPadding}px` : "40px 40px 0 40px",
              gap: isMobile ? textPadding * 2 : 40,
              background: `url("img/BG_2.jpg") no-repeat center center / cover`,
            }}>
            <div
              style={{
                backgroundColor: "rgba(8, 5, 23, 0.8)",
                backdropFilter: "blur(10px)",
                borderTop: "1px solid rgba(255, 255, 255, 0.7)",
                borderBottom: "1px solid rgba(255, 255, 255, 0.7)",
                marginTop: Math.floor(contentWidth * 0.567 * 0.1),
                padding: isMobile ? `${textPadding}px` : "40px",
                width: "50%",
                fontFamily: "InputMonoRegular, monospace",
                fontSize: isMobile ? "2.5vw" : isMid ? 10 : 14,
                lineHeight: 1.5,
              }}>
              We watched you make your campfires, build villages, raise mighty cities, and then space stations.
              Did you stop to remember us in your hurry to become gods? Did you hear us ask to share your magic? No?
            </div>
          </Flex>
          <Flex
            direction="column"
            alignItems="flex-start"
            style={{
              height: Math.floor(contentWidth * 0.567),
              padding: isMobile ? `${textPadding}px` : "40px 40px 0 40px",
              gap: isMobile ? textPadding * 2 : 40,
              background: `url("img/BG_3.jpg") no-repeat center center / cover`,
            }}>
            <div
              style={{
                backgroundColor: "rgba(8, 5, 23, 0.8)",
                backdropFilter: "blur(10px)",
                borderTop: "1px solid rgba(255, 255, 255, 0.7)",
                borderBottom: "1px solid rgba(255, 255, 255, 0.7)",
                marginTop: Math.floor(contentWidth * 0.567 * 0.1),
                padding: isMobile ? `${textPadding}px` : "40px",
                width: "50%",
                fontFamily: "InputMonoRegular, monospace",
                fontSize: isMobile ? "2.5vw" : isMid ? 10 : 14,
                lineHeight: 1.5,
              }}>
              You guys weren’t very good at listening, were you? But you were good at talking.
              You called us savages. Denounced us as an evolutionary beta test with undercooked brains, too much fur, and a propensity for throwing shit.
            </div>
          </Flex>
          <Flex
            direction="column"
            alignItems="flex-end"
            style={{
              height: Math.floor(contentWidth * 0.567),
              padding: isMobile ? `${textPadding}px` : "40px 40px 0 40px",
              gap: isMobile ? textPadding * 2 : 40,
              background: `url("img/BG_4.jpg") no-repeat center center / cover`,
            }}>
            <div
              style={{
                backgroundColor: "rgba(8, 5, 23, 0.8)",
                backdropFilter: "blur(10px)",
                borderTop: "1px solid rgba(255, 255, 255, 0.7)",
                borderBottom: "1px solid rgba(255, 255, 255, 0.7)",
                marginTop: Math.floor(contentWidth * 0.567 * 0.1),
                padding: isMobile ? `${textPadding}px` : "40px",
                width: "50%",
                fontFamily: "InputMonoRegular, monospace",
                fontSize: isMobile ? "2.5vw" : isMid ? 10 : 14,
                lineHeight: 1.5,
              }}>We ruled the few remaining jungles, but your kind didn’t find us worthy to share this planet with, let alone the stars.
              Space was never meant for us... until now.
            </div>
          </Flex>
          <Flex
            alignItems="center"
            style={{
              borderTop: "1px solid rgba(255, 255, 255, 0.7)",
              padding: isMobile ? `${textPadding + 10}px ${textPadding}px ${textPadding}px ${textPadding}px` : "40px",
              lineHeight: 1.5,
              marginBottom: isMobile ? "10px" : "10px",
            }}>
            <Flex justifyContent="center" position="relative">
              <img
                src="/img/Krill-PFP.png"
                width={isMobile ? "80px" : "100px"}
                height={isMobile ? "80px" : "100px"}
                style={{
                  borderRadius: "50%",
                }} />
              <a href="https://twitter.com/captnkrill">
                <img
                  src="/img/icontwitter-blue.svg"
                  width="20px"
                  height="20px"
                  style={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    background: "rgba(0, 0, 0, 0.5",
                    borderRadius: "50%",
                    padding: "4px",
                  }} />
              </a>
            </Flex>
            <Flex marginLeft="4%"
              style={{
                fontFamily: "InputMonoRegular, monospace",
                fontSize: isMobile ? "2.5vw" : isHiRes ? 20 : 16,
              }}>
              ABOUT THE ARTIST<br />&nbsp;<br />
              Captain Krill is a US-based, multidisciplinary creative seeking to bring immersive
              new worlds to life through collaboration with AI.
            </Flex>
          </Flex>
        </Flex>
        <Copyright />
      </Flex >
      {
        isMobile ? <></> : <Carousel />
      }
    </AppStyled >
  );
}

export default App;
